<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <router-link tag="a" to="/records/machinery" class="button xbtn is-medium is-transparent">
                  <icon name="x"></icon>
                </router-link>
                <h2 class="title" v-if="isNew">Nowa maszyna</h2>
                <h2 class="title" v-else>Edytuj maszyne</h2>
              </div>
              <div v-if="isOnline" class='buttons level-right'>
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="errors['name'] && errors['name'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Nazwa</label>
              <span v-if="errors['name'] && errors['name'].length > 0"
                class="help is-danger">{{ errors['name'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="name">
                  <input name="name" v-model="machinery.name" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['type'] && errors['type'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Typ</label>
              <span v-if="errors['type'] && errors['type'].length > 0"
                class="help is-danger">{{ errors['type'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="type">
                  <input name="type" v-model="machinery.type" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['serial'] && errors['serial'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Numer seryjny</label>
              <span v-if="errors['serial'] && errors['serial'].length > 0"
                class="help is-danger">{{ errors['serial'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="serial">
                  <input name="serial" v-model="machinery.serial" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
    
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions, mapGetters} from 'vuex'

  import {
    ValidationObserver,
    ValidationProvider
  } from 'vee-validate';

  export default {
    name: 'MachineryForm',
    props: {
      id: {
        type: Number,
        default: 0
      }
    },
    components: {
      FixedHeader,
      ValidationObserver,
      ValidationProvider
    },
    data: function () {
      return {
        machinery: {}
      }
    },
    computed: {
      ...mapGetters({
        selectADevice: 'orders/selectADevice'
      }),       
      isNew() {
        return this.id === 0 ? true : false
      }
    },
    methods: {
      ...mapActions({
        getMachinery: 'records/getMachinery',
        postMachinery: 'records/postMachinery',
        disabledSidebar: 'setDisabledSidebar'
      }),         
      onSubmit() {
        this.postMachinery({machinery: this.machinery, id: this.$route.params.id })
          .then(resp => {
            this.$router.push({name: 'MachineryDetails', params: { id: resp.data.id }})
          })
          .catch((error) => {
            this.machinery = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          }) 
      },
    },
    mounted() {
      this.disabledSidebar(true)
      if(!this.isNew) {
        this.getMachinery({id: this.$route.params.id})
          .then(resp => this.machinery = resp)
          .catch((error) => {
            this.machinery = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      }
    },
    destroyed() {
      if(!this.selectACustomer) {
        this.disabledSidebar(false)
      }
    }
  }
</script>